import MessageMap from '../i18n.d';

const esMX: MessageMap = {
  pocInformation: {
    title: 'Información del PDV y gestión de pedidos',
    pocID: 'ID del PDV',
    pocName: 'Nombre del PDV',
  },

  Loading: {
    BEELOADING_TEXT: 'Procesando…',
  },

  buttonRequest: {
    request: 'Solicitar',
  },

  inputTicketID: {
    title: '¿Cuál es el ID del ticket?',
    ticketID: 'Ingresar ID del ticket',
    error: 'No pudimos encontrar este ticket. Por favor, verifica el ID y vuelve a intentar.',
  },

  selectMotive: {
    titleMotive: 'Razón',
    motive: 'Seleccionar razón',
  },

  selectExchange: {
    title: 'Por favor, seleccionar una acción',
    selectValue: 'Seleccionar una acción para comenzar',
    requestExchange: 'Solicitar un cambio',
    updateAddress: 'Actualizar domicilio',
    error:
      'La categoría del boleto no coincide con la acción deseada, seleccione la acción correspondiente o ingrese otra identificación del boleto',
  },

  changeableUnits: {
    unitsExchange: 'Cantidad a cambiar',
    errorText: 'Cantidad superior a la permitida por la Orden',
  },

  buttonOrder: {
    selectOrder: 'Seleccionar pedido',
    changeOrder: 'Cambiar pedido',
  },

  measureUnit: {
    titleUnit: 'Unidad de medida',
    selectUnit: 'Seleccionar unidad',
  },

  selectOrderModal: {
    title: 'Seleccionar pedido',
    buttonBack: 'Volver',
    buttonSelect: 'Seleccionar',
  },

  optionsMotive: {
    broken: 'Producto roto',
    wrong: 'Producto equivocado',
    violated: 'Product averiado',
    expired: 'Producto vencido',
    liquidQuality: 'Calidad del líquido',
  },

  ordersDetails: {
    orderNumber: 'Numero del pedido',
    invoiceNumber: 'Numero del recibo',
    deliveryDate: 'Fecha de envío',
  },

  optionsMensure: {
    boxes: 'Cajas',
    units: 'Unidades',
  },

  pagination: {
    of: 'de',
  },

  toastMessages: {
    info: 'Actualizaciones enviadas exitosamente.',
    error: 'Algo salió mal. Por favor, intenta nuevamente. ',
  },

  requestExchange: {
    selectProduct: 'Seleccionar producto',
    product: 'Seleccionar producto para cambiar',
  },

  addButton: {
    product: 'Añadir otro producto',
  },
};

export default esMX;
