import React from 'react';
import BeeLoading from '../beeLoading/BeeLoading';
import { useToggleLoad } from '../../hooks/toggleLoad/useToggleLoad';

interface LoadingWrapperProps {
  children: JSX.Element;
}

const LoadingWrapper = ({ children }: LoadingWrapperProps): JSX.Element => {
  const { load } = useToggleLoad();

  return load ? <BeeLoading testid="tlp-mfe-wrapper-loading" /> : children;
};

export default React.memo(LoadingWrapper);
