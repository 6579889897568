import MessageMap from '../i18n';

const ptBR: MessageMap = {
  pocInformation: {
    title: 'Informação do PDV e gestão de pedidos',
    pocID: 'ID do PDV',
    pocName: 'Nome do PDV',
  },

  Loading: {
    BEELOADING_TEXT: 'Processando…',
  },

  inputTicketID: {
    title: 'Qual a ID do ticket?',
    ticketID: 'Insira a ID do seu ticket',
    error:
      'Não conseguimos encontrar o ticket. Verifique o número de identificação e tente novamente.',
  },

  selectExchange: {
    title: 'Escolha uma ação',
    selectValue: 'Escolha uma ação para iniciar',
    requestExchange: 'Selecionar troca',
    updateAddress: 'Atualizar endereço',
    error:
      'A categoria do ticket não corresponde à ação desejada, selecione a ação correspondente ou insira outro ID do ticket',
  },

  buttonOrder: {
    selectOrder: 'Selecionar pedido',
    changeOrder: 'Mudar o pedido',
  },

  selectOrderModal: {
    title: 'Selecionar pedido',
    buttonBack: 'Voltar',
    buttonSelect: 'Selecionar',
  },

  ordersDetails: {
    orderNumber: 'Número do pedido',
    invoiceNumber: 'Número da nota fiscal',
    deliveryDate: 'Data de entrega',
  },

  pagination: {
    of: 'de',
  },

  requestExchange: {
    selectProduct: 'Selecionar produto',
    product: 'Selecione o produto a ser trocado',
  },

  optionsMensure: {
    boxes: 'Caixas',
    units: 'Unidades',
  },

  optionsMotive: {
    broken: 'Produto quebrado',
    wrong: 'Produto errado',
    violated: 'Produto avariado',
    expired: 'Produto vencido',
    liquidQuality: 'Qualidade do líquido',
  },

  measureUnit: {
    titleUnit: 'Unidade de medida',
    selectUnit: 'Selecionar unidade',
  },

  changeableUnits: {
    unitsExchange: 'Quantidade a trocar',
    errorText: 'Acima do permitido',
  },

  selectMotive: {
    titleMotive: 'Razão',
    motive: 'Selecionar razão',
  },

  buttonRequest: {
    request: 'Solicitar',
  },

  toastMessages: {
    info: 'Atualizações enviadas com sucesso.',
    error: 'Algo deu errado. Tente novamente.',
  },

  addButton: {
    product: 'Adicione outro produto',
  },
};

export default ptBR;
