// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import { DisappearedLoading } from 'react-loadingg';
import Bee from '../../assets/Bee';
import BeeLoadingStyle from './BeeLoading.styles';

interface Props {
  style?: unknown;
  testid: string;
}

const BeeLoading = ({ style, testid }: Props): JSX.Element => {
  const classes = BeeLoadingStyle();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      <div className={classes.loadingContainer} data-testid={`${testid}-bee-loading-component`}>
        <Bee />
        <DisappearedLoading color="#000" size="small" style={style} />
      </div>
      <Typography className={classes.loadingText} data-testid="loading-text">
        {formatMessage({ id: 'Loading.BEELOADING_TEXT' })}
      </Typography>
    </div>
  );
};

BeeLoading.defaultProps = {
  style: {
    position: 'unset',
    width: '40px',
    height: '30px',
    marginLeft: '-10px',
  },
};

export default BeeLoading;
