import { useFeatureToggleService } from 'admin-portal-shared-services';
import { useState } from 'react';
import { useTogglesMock } from '../togglesMock/useTogglesMock';

export interface IUseToggleLoad {
  load: boolean;
}

export const useToggleLoad = (): IUseToggleLoad => {

  const [load, setLoad] = useState(true);

  const optInstance = useFeatureToggleService().getInstance();
  optInstance.onReady().then(() => setLoad(false))

  useTogglesMock();

  return { load };
};
