import { useAuthenticationService } from 'admin-portal-shared-services';
import { UserInfoToken } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import { Locale } from 'date-fns';
import { enUS } from 'date-fns/locale';
import React from 'react';

export type IAuthContext = {
  currentUser: UserInfoToken | null;
  locale?: Locale | null;
};

const store: IAuthContext = {
  currentUser: null,
  locale: null,
};

export const AuthContext = React.createContext(store);
export const useAuthContext = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const authService = useAuthenticationService();
  const currentUser = authService.parseJwt();
  const locale = enUS;

  return (
    <AuthContext.Provider value={{ currentUser, locale }}>{children}</AuthContext.Provider>
  );
};
