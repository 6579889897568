import { DynamicForms } from "../interface/DynamicFormsResponse";
import { TicketDetail } from "../interface/ticketDetailResponse";
import { COMPANY_TOGGLE_PROD, COMPANY_TOGGLE_UAT } from "../services/constants";

export const updateTicketFields = (mappingFields: any, values: any, ticket: TicketDetail) => {
    Object.keys(mappingFields).forEach(element => {
        ticket.metadata[mappingFields[element]] = values[element];
    });

    return ticket;
}

export const updateDynamicFormsFields = (mappingFields: any, values: any, dynamicForm: DynamicForms) => {
    Object.keys(mappingFields).forEach(element => {
        const index = dynamicForm.form.fields_value.findIndex(field => field.id === mappingFields[element]);
        if (index > -1) {
            dynamicForm.form.fields_value[index] = { id: mappingFields[element], value: values[element] };
        }
        else {
            dynamicForm.form.fields_value.push({ id: mappingFields[element], value: values[element] });
        }
    });

    return dynamicForm;
}

export const getFieldStructure = (fieldId: number, dynamicForm: DynamicForms) => {
    return dynamicForm?.form?.fields_structure?.find(element => element.id === fieldId);
}

export const isCanary = (): boolean | false => {
    return localStorage.getItem('canary') === 'simple-canary'; // check if localStorage has this
};

export const getEnvironment = (): string => {
    const portalLocalStorage = localStorage.getItem('portal-config') || '{}';
    const portalConfig = JSON.parse(portalLocalStorage);
    switch (portalConfig.ENV) {
        case 'qa':
            return 'SIT';
        case 'uat':
            return 'UAT';
        case 'prod':
            return 'PROD';
        case 'localhost':
            return 'localhost';
        default:
            return 'DEV';
    }
}

export function getVendorName(vendorId: string): string {
    const vendor =
        getEnvironment() !== 'PROD' ? COMPANY_TOGGLE_UAT[vendorId] : COMPANY_TOGGLE_PROD[vendorId];

    return vendor?.toUpperCase().replace('_', '');
}