import React from 'react';

export type IEnvContext = {
  segmentKey: string;
  NODE_ENV: string;
  fieldsMapping: string;
};

const store: IEnvContext = {
  segmentKey: '',
  NODE_ENV: '',
  fieldsMapping:  "{\"request-exchange\":{\"formId\": 1500000582722,\"product\": 1500003437322,"+
  "\"deliveryDate\": 1500003380341,\"motivation\": 1500003437122,\"invoiceNumber\": 1500003435882}}",
};

export const EnvContext = React.createContext(store);
export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: IEnvContext; children: React.ReactNode }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
