import { useFeatureToggleService } from 'admin-portal-shared-services';
import {
  MULTIPLE_PRODUCT_EXCHANGE,
} from '../../config/featureToggles';

export function useTogglesMock() {
  const featureToggleService = useFeatureToggleService();
  if (process.env.mockToggles) {
    featureToggleService.mockFeatureToggle(MULTIPLE_PRODUCT_EXCHANGE, false);
  }
}
