import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useFeatureToggleService } from 'admin-portal-shared-services';
import LoadingWrapper from './components/loadingWrapper/LoadingWrapper';
import { AuthProvider } from './contexts/AuthProvider';
import { EnvProvider, IEnvContext } from './contexts/EnvProvider';
import { IntlProvider } from './i18n';
import Router from './Router';

export default function App(props: IEnvContext) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-beescare-ticket-tools-mfe',
    seed: 'bees-hub-beescare-ticket-tools-mfe',
  });

  useFeatureToggleService().loadToggles();

  const queryClient = new QueryClient();

  return (
    <EnvProvider env={props}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <StylesProvider generateClassName={generateClassName}>
            <IntlProvider>
              <LoadingWrapper>
                <Router />
              </LoadingWrapper>
            </IntlProvider>
          </StylesProvider>
        </AuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
    </EnvProvider>
  );
}
