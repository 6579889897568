import { useFeatureToggleService, useUserMetadata } from 'admin-portal-shared-services';
import { getVendorName, isCanary } from './utils';

const CANARY = 'canary';

const getZone = (country?: string): string | undefined => {
    if (country?.includes('_')) {
        return country;
    }

    const { data } = useUserMetadata();
    const vendorId: string = data?.vendors ? data.vendors[0].id : '';

    const vendorName = getVendorName(vendorId)?.toLowerCase();
    const isVendor = !!vendorName?.toLocaleLowerCase();

    return isVendor ? `${vendorName}_${country?.toLowerCase()}` : country;
};

export function isFeatureEnabled(toggle: string, country?: string): boolean {
    const zone = getZone(country);

    if (zone && isCanary()) {
        return useFeatureToggleService().isFeatureEnabled(toggle, `${CANARY}_${zone.toLowerCase()}`);
    }

    return useFeatureToggleService().isFeatureEnabled(toggle, zone);
}

export const MULTIPLE_PRODUCT_EXCHANGE = 'multiple_product_exchange';
