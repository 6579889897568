import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },

  Loading: {
    BEELOADING_TEXT: 'Processing…',
  },

  pocInformation: {
    title: 'POC information and orders management',
    pocID: 'POC ID',
    pocName: 'POC Name',
  },

  inputTicketID: {
    title: 'What is the ticket ID?',
    ticketID: 'Insert your ticket ID',
    error: 'We could not find this ticket. Please check its ID number and try again.',
  },

  selectExchange: {
    title: 'Please, select an action',
    selectValue: 'Select an action to start',
    requestExchange: 'Request exchange',
    updateAddress: 'Update address',
    error:
      'The ticket category does not match the desired action, please select the corresponding action or enter another ticket id',
  },

  buttonOrder: {
    selectOrder: 'Select order',
    changeOrder: 'Change order',
  },

  selectOrderModal: {
    title: 'Select order',
    buttonBack: 'Back',
    buttonSelect: 'Select',
  },

  ordersDetails: {
    orderNumber: 'Order Number',
    invoiceNumber: 'Invoice Number',
    deliveryDate: 'Delivery Date',
  },

  pagination: {
    of: 'of',
  },

  requestExchange: {
    selectProduct: 'Select products',
    product: 'Select product to be exchanged',
  },

  optionsMensure: {
    boxes: 'Boxes',
    units: 'Units',
  },

  optionsMotive: {
    broken: 'Broken product',
    wrong: 'Wrong product',
    violated: 'Product violated',
    expired: 'Expired product',
    liquidQuality: 'Liquid quality',
  },

  measureUnit: {
    titleUnit: 'Measure unit',
    selectUnit: 'Select unit',
  },

  changeableUnits: {
    unitsExchange: 'Quantity to exchange',
    errorText: 'Quantity above that allowed by the Order',
  },

  selectMotive: {
    titleMotive: 'Motive',
    motive: 'Select motive',
  },

  buttonRequest: {
    request: 'Request',
  },

  toastMessages: {
    info: 'Updates successfully submitted.',
    error: 'Something went wrong. Please try again.',
  },

  addButton: {
    product: 'Add another product',
  },
};

export default enUS;
